<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="CBA契約" />
    <AppSideMenu />
    <main id="main" class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <!--契約情報-->
        <div class="l-contents l-box">
          <div class="l-box title-wrap l-flex">
            <h3 class="c-lead icon">契約情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>代理登録</label>
              </div>
              <div class="c-inputWrap__items">
                {{ proxyReserveInfo }}
              </div>
            </div>
          </div>
        </div>
      </div>

    </main>

    <AppFooter />
  </div>
</template>

<script>
import callApi from "./mixins/callApi";

export default {
  name: "disposer",
  components: {
  },
  mixins: [callApi],
  data() {
    return {
      isDisposalProxy: false,
      errMsgs: [],
    };
  },
  computed: {
    isDisposer() {
      return this.$store.getters["userInfo/isDisposer"];
    },
    proxyReserveInfo() {
      return this.isDisposalProxy ? '有' : '無'
    }
  },
  methods: {
  },
  mounted() {
    this.getDisposerContractApi().then((data) => {
      this.isDisposalProxy = data.isDisposalProxy;
    });
  },
};
</script>
